import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";

const validateVendorInvitationToken = async (_, invitationToken) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `j-idp/v1/pub/vendor/validate-token?token=${invitationToken}`;
  return await proxy.execute(query);
};

const signupVendor = async (_, payload) => {
  if (!payload.invitationToken) {
    return;
  }
  const proxy = new PostSimpatraServiceProxy();
  const query = "j-idp/v1/pub/vendor/sign-up";
  return await proxy.execute(query, payload, {
    notificationParams: {
      displaySuccessMessage: true,
      successNotificationMsg: "Successfully created vendor!",
    },
  });
};

const signupOrg = async (_, payload) => {
  if (!payload) {
    return;
  }
  const proxy = new PostSimpatraServiceProxy();
  const query = "j-idp/v1/pub/org/sign-up";
  return await proxy.execute(query, payload, {
    notificationParams: {
      displaySuccessMessage: true,
      successNotificationMsg: "Successfully created organization!",
    },
  });
};

export default {
  validateVendorInvitationToken,
  signupVendor,
  signupOrg,
};
