import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state: {
    formData: {
      vendorInformation: null,
      addressInformation: null,
      userAccountSetup: null
    },
    vendorInvitationToken: null
  },
  getters,
  mutations,
  actions,
};
