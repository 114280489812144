import { get } from "lodash";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const fetchProductByVinAndSlug = async ({ commit }, { vin, slug, queryParams }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `products/v1/market/product/${vin}/${slug}`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    commit("setSelectedProduct", response.data);
    return data;
  }
};

const fetch503AProducts = async (_, { queryParams }) => {
  const proxy = new GetSimpatraServiceProxy();
  const currentLocation = get(localStorage, "currentLocation", null);
  const query = `products/v1/location/${currentLocation}/market/pharmacy/search`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    return data;
  }
  return null;
};

export default {
  fetchProductByVinAndSlug,
  fetch503AProducts,
};
