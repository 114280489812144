// import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
// import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";
// import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
// import PatchSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/patch";

const fetchPatientDetails = async (_, patientId) => {
 
};

export default {
  fetchPatientDetails,
};

