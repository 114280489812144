const setFormData = (state, { type, data }) => {
  state.formData[type] = data;
};

const setVendorInvidationToken = (state, data) => {
  state.vendorInvitationToken = data;
};

const clearState = (state) => {
  state = {
    formData: {
      vendorInformation: null,
      addressInformation: null,
      userAccountSetup: null
    },
    vendorInvitationToken: null
  }
}

export default {
  setFormData,
  setVendorInvidationToken,
  clearState,
};
