const defaultPaginationDetails = {
  currentPage: 1,
  totalPages: 0,
  pageSize: 10,
  totalData: 0,
  reportTotal: 0
};

const initialReportFilters = {
  purchaseType: undefined,
  paymentType: undefined,
  dateRange: undefined,
  generatedId: "",
  orgId: undefined,
  locationId: undefined,
  reportType: "order",
  // CUSTOMS
  selectedOrganization: undefined,
};

export default {
  defaultPaginationDetails,
  reportList: {
    data: [],
    initialReportFilters,
    filters: initialReportFilters,
    paginationDetails: defaultPaginationDetails,
  },
};
